import React, { useState, useEffect } from "react";

import "./App.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import moment from "moment";
import { API_URL } from "./Global";
import logo from "../src/images/logo.png";
import {
    Col,
    Row,
    ButtonGroup,
    ToggleButton,
    Modal,
    Navbar,
    Container,
    Nav,
    NavDropdown,
} from "react-bootstrap";
import { Autocomplete } from "@mui/material";
import { HashRouter, Routes, Route, Link } from "react-router-dom";

import Login from "./Components/auth/Login";
import GSTBill from "./Components/GSTBill/GSTBill";
import NONGSTBill from "./Components/NONGSTBILL/NONGSTBill";
import QuoteHistory from "./Components/Quotation/QuoteHistory";
import GSTBillHistory from "./Components/GSTBill/GSTBillHistory";
import NONGSTBillHistory from "./Components/NONGSTBILL/NONGSTBillHistory";
import ExpensesManager from "./Components/ExpensesManger/ExpensesManager";
import Quote from "./Components/Quotation/Quote";
import Analytics from "./Components/analytics/Analytics";
import GSTNONGSTHistory from "./Components/GSTNONGSTHistory";
import WorkerManager from "./Components/worker_manager/WorkerManager";
import PresentyManager from "./Components/presenty_manager/PresentyManager";
import LedgerManager from "./Components/ledger_manager/LedgerManager";
import Inquiry from "./Components/inquiry/Inquiry";
import InquiryHistory from "./Components/inquiry/InquiryHistory";
import InquiryProduct from "./Components/InquiryProduct";
import BasicRates from "./Components/BasicRates ";
import PartyManger from "./Components/PartyManger";
import Dashboard from "./Components/dashboard/Dashboard";

const axios = require("axios");

export const FilterContext = React.createContext();
export const AuthContext = React.createContext();

export default function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem("isLoggedIn") == "true" ? true : false
    );
    const [userType, setUserType] = useState(
        localStorage.getItem("userType") != "0"
            ? Number(localStorage.getItem("userType"))
            : false
    );
    const [currentType, setCurrentType] = useState(2);
    const [currentCompany, setCurrentCompany] = useState(0);
    const [products, setProducts] = useState(null);
    const [currentProduct, setCurrentProduct] = useState("");
    const [currentUnit, setCurrentUnit] = useState("Sq.ft.");
    const [currentUnitPrice, setCurrentUnitPrice] = useState(0);

    // ##############----SATISH'S CHANGES----###############----START----##################
    const [currentUnitBasePrice, setCurrentUnitBasePrice] = useState(0);
    // ##############----SATISH'S CHANGES----###############----END----##################

    const [currentWidth, setCurrentWidth] = useState(1);
    const [currentHeight, setCurrentHeight] = useState(1);
    const [currentSqft, setCurrentSqft] = useState(1);
    const [currentQuantity, setCurrentQuantity] = useState(1);
    const [currentPrice, setCurrentPrice] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);

    const [activeTab, setActiveTab] = useState(0);

    const Company = [
        { label: "Imprint 24", name: "Imprint 24", value: "1" },
        { label: "Sign Mart", name: "Sign Mart", value: "2" },
    ];
    const Types = [
        { label: "Mediator", name: "Mediator", value: "1" },
        { label: "Retailer", name: "Retailer", value: "2" },
    ];

    const Unit = [{ label: "Sq.ft." }, { label: "Inch" }, { label: "MM" }];

    const insertNewProduct = () => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `insert into products (name, price, base_price, customerType, company) values ('${currentProduct}', ${currentUnitPrice}, ${currentUnitBasePrice}, ${currentType}, ${currentCompany})`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("insert successful");
                toast.success("Product added successfully");
                setTimeout(() => {
                    fetchProductList();
                }, 3000);
            })
            .catch((err) => {
                console.log("products insert error: ", err);
            });
    };

    const deleteProduct = (id) => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `update products set status = 0 where id = ${id}`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("delete successful");
                toast.error("Product deleted successfully");
                setTimeout(() => {
                    fetchProductList();
                }, 3000);
            })
            .catch((err) => {
                console.log("products fetch error: ", err);
            });
    };

    const updateSqft = (type, value) => {
        let multiplier =
            currentUnit === "Sq.ft." ? 1 : currentUnit === "Inch" ? 144 : 92903;
        if (type === "width") {
            setCurrentSqft(
                (
                    ((value * currentHeight) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "height") {
            setCurrentSqft(
                (
                    ((currentWidth * value) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "unit") {
            let multiplier =
                value === "Sq.ft." ? 1 : value === "Inch" ? 144 : 92903;
            setCurrentSqft(
                (
                    ((currentWidth * currentHeight) / multiplier) *
                    currentQuantity
                ).toFixed(2)
            );
        } else if (type === "quantity") {
            setCurrentSqft(
                (((currentWidth * currentHeight) / multiplier) * value).toFixed(
                    2
                )
            );
        }
    };

    const updateCurrentPrice = (type, value) => {
        let divisor =
            currentUnit === "Sq.ft." ? 1 : currentUnit === "Inch" ? 144 : 92903;

        if (type === "unit") {
            setCurrentUnit(value);
            let divisor =
                value === "Sq.ft." ? 1 : value === "Inch" ? 144 : 92903;
            setCurrentPrice(
                (
                    (currentWidth *
                        currentHeight *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "unitPrice") {
            setCurrentUnitPrice(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * value * currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "quantity") {
            setCurrentQuantity(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * currentUnitPrice * value) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "product") {
            setCurrentUnitPrice(value);
            setCurrentPrice(
                (
                    (currentWidth * currentHeight * value * currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "width") {
            setCurrentWidth(value);

            setCurrentPrice(
                (
                    (value *
                        currentHeight *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        } else if (type === "height") {
            setCurrentHeight(value);
            setCurrentPrice(
                (
                    (currentWidth *
                        value *
                        currentUnitPrice *
                        currentQuantity) /
                    divisor
                ).toFixed(2)
            );
        }
        updateSqft(type, value);
    };

    const fetchProductList = () => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `select id, name as label, price, base_price from products where status = 1 and customerType = ${currentType} and company = ${currentCompany}`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => {
                console.log("products fetch error: ", err);
            });
    };
    useEffect(() => {
        fetchProductList();
    }, [currentType, currentCompany]);


    const refresh = () => {
        window.location.reload(false);
    };

    return (
        <div>
            <AuthContext.Provider
                value={[
                    [isLoggedIn, setIsLoggedIn],
                    [userType, setUserType],
                ]}
            >
                <FilterContext.Provider
                    value={{
                        currentType: currentType,
                        currentCompany: currentCompany,
                    }}
                >
                    <HashRouter>
                        {isLoggedIn && (
                            <div className="container">
                                <div class="row">
                                    <div className="col-sm-12 mb-2">
                                        <h3
                                            className="text-center text-white p-3 w-50 m-auto"
                                            style={{
                                                backgroundColor: "#fff",
                                            }}
                                        >
                                            <img
                                                src={logo}
                                                alt="logo"
                                                className="img-fluid  w-75"
                                            />
                                        </h3>
                                    </div>
                                    <div className="col-12 col-md-6 text-center">
                                        <ButtonGroup>
                                            {Company.map((radio, idx) => (
                                                <ToggleButton
                                                    key={idx}
                                                    id={`company-${idx}`}
                                                    type="radio"
                                                    variant={
                                                        idx % 2
                                                            ? "outline-success"
                                                            : "outline-success"
                                                    }
                                                    name="company"
                                                    value={radio.value}
                                                    checked={
                                                        currentCompany ===
                                                        radio.value
                                                    }
                                                    onChange={(e) =>
                                                        setCurrentCompany(
                                                            e.currentTarget
                                                                .value
                                                        )
                                                    }
                                                    className="ToggleClassBtnRadio1"
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </div>
                                    <div className="col-12 col-md-6 text-center">
                                        <ButtonGroup>
                                            {Types.map((radio, idx) => (
                                                <ToggleButton
                                                    key={idx}
                                                    id={`radio-${idx}`}
                                                    type="radio"
                                                    variant={
                                                        idx % 2
                                                            ? "outline-danger"
                                                            : "outline-danger"
                                                    }
                                                    name="radio"
                                                    value={radio.value}
                                                    checked={
                                                        currentType ===
                                                        radio.value
                                                    }
                                                    onChange={(e) =>
                                                        setCurrentType(
                                                            e.currentTarget
                                                                .value
                                                        )
                                                    }
                                                    className="ToggleClassBtnRadio1"
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <Navbar
                                    collapseOnSelect
                                    expand="lg"
                                    className="mt-3 text-dark"
                                    style={{
                                        backgroundColor: "#badcf9",
                                        height: "50px",
                                    }}
                                >
                                    <Container
                                        style={{
                                            backgroundColor: "#badcf9",
                                            zIndex: "100",
                                        }}
                                    >
                                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                        <Navbar.Collapse id="responsive-navbar-nav">
                                            <Nav className="mx-auto ">
                                                <Nav.Link
                                                    className={
                                                        activeTab === 15
                                                            ? "bg-warning border border-warning rounded  mr-3"
                                                            : "mr-3"
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(15)
                                                    }
                                                >
                                                    <Link
                                                        to="/"
                                                        className="mt-3 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Dashboard
                                                    </Link>
                                                </Nav.Link>
                                                <Nav.Link
                                                    className={
                                                        activeTab === 1
                                                            ? "bg-warning border border-warning rounded  mr-3"
                                                            : "mr-3"
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(1)
                                                    }
                                                >
                                                    <Link
                                                        to="/calc"
                                                        className="mt-3 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Calc.
                                                    </Link>
                                                </Nav.Link>
                                                <Nav.Link
                                                    className={
                                                        (activeTab === 10
                                                            ? "bg-warning border border-warning rounded mr-5 mr-3"
                                                            : "mr-3") +
                                                        (userType == 1
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(10)
                                                    }
                                                >
                                                    <Link
                                                        to="/inquiry"
                                                        className="mt-3 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Inquiry.
                                                    </Link>
                                                </Nav.Link>

                                                <Nav.Link
                                                    className={
                                                        (activeTab === 2
                                                            ? "bg-warning border border-warning rounded mr-3"
                                                            : "mr-3") +
                                                        (userType != 1
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(2)
                                                    }
                                                >
                                                    <Link
                                                        to="/quote"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Quote.
                                                    </Link>
                                                </Nav.Link>
                                                <Nav.Link
                                                    className={
                                                        (activeTab === 3
                                                            ? "bg-warning border border-warning rounded mr-3"
                                                            : "mr-3") +
                                                        (userType != 1
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(3)
                                                    }
                                                >
                                                    <Link
                                                        to="/gstBill"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        GST Bill
                                                    </Link>
                                                </Nav.Link>
                                                <Nav.Link
                                                    className={
                                                        (activeTab === 4
                                                            ? "bg-warning border border-warning rounded mr-3"
                                                            : "mr-3") +
                                                        (userType != 1
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(4)
                                                    }
                                                >
                                                    <Link
                                                        to="/nonGstBill"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Non GST Bill
                                                    </Link>
                                                </Nav.Link>
                                                {/* <Nav.Link
                                                    className={
                                                        (activeTab === 5
                                                            ? "bg-warning border border-warning rounded mr-3"
                                                            : "mr-3") +
                                                        (userType != 1
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(5)
                                                    }
                                                >
                                                    <Link
                                                        to="/expenses"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Expenses
                                                    </Link>
                                                </Nav.Link> */}
                                                {/* <Nav.Link
                                                    className={
                                                        (activeTab === 6
                                                            ? "bg-warning border border-warning rounded mr-3"
                                                            : "mr-3") +
                                                        (userType != 1
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(6)
                                                    }
                                                >
                                                    <Link
                                                        to="/workers"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Workers
                                                    </Link>
                                                </Nav.Link> */}
                                                <Nav.Link
                                                    className={
                                                        (activeTab === 7
                                                            ? "bg-warning border border-warning rounded mr-3"
                                                            : "mr-3") +
                                                        (userType != 1
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(7)
                                                    }
                                                >
                                                    <Link
                                                        to="/products"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Products
                                                    </Link>
                                                </Nav.Link>
                                                {/* <Nav.Link
                                                    className={
                                                        (activeTab === 8
                                                            ? "bg-warning border border-warning rounded mr-3"
                                                            : "mr-3") +
                                                        (userType != 1
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(8)
                                                    }
                                                >
                                                    <Link
                                                        to="/inquiryProduct"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Inquiry Product
                                                    </Link>
                                                </Nav.Link> */}
                                                {/* <Nav.Link
                                                    className={
                                                        (activeTab === 9
                                                            ? "bg-warning border border-warning rounded mr-3"
                                                            : "mr-3") +
                                                        (userType != 1
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(9)
                                                    }
                                                >
                                                    <Link
                                                        to="/BasicRates"
                                                        className="mt-2 h6"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Basic Rates
                                                    </Link>
                                                </Nav.Link> */}
                                                <NavDropdown
                                                    title="History"
                                                    id="collasible-nav-dropdown"
                                                    className={
                                                        (activeTab === 10
                                                            ? "bg-warning border border-warning rounded mr-3 h6"
                                                            : "h6 mr-3 ") +
                                                        (userType == 3
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(10)
                                                    }
                                                >
                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            setActiveTab(10)
                                                        }
                                                        className={
                                                            userType != 1
                                                                ? " d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <Link
                                                            to="/quoteHistory"
                                                            className="mt-2 h6"
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            Quote History
                                                        </Link>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            setActiveTab(10)
                                                        }
                                                        className={
                                                            userType != 1
                                                                ? " d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <Link
                                                            to="/gstHistory"
                                                            className="mt-2 h6"
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            GST History
                                                        </Link>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            setActiveTab(10)
                                                        }
                                                        className={
                                                            userType != 1
                                                                ? " d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <Link
                                                            to="/nonGstHistory"
                                                            className="mt-2 h6"
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            Non GST History
                                                        </Link>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            setActiveTab(10)
                                                        }
                                                        className={
                                                            userType != 1
                                                                ? " d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <Link
                                                            to="/billHistory"
                                                            className="mt-2 h6"
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            Bill History
                                                        </Link>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            setActiveTab(10)
                                                        }
                                                        className={
                                                            userType != 1
                                                                ? " d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <Link
                                                            to="/analytics"
                                                            className="mt-2 h6"
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            Analytics
                                                        </Link>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            setActiveTab(10)
                                                        }
                                                        className={
                                                            userType == 3
                                                                ? " d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <Link
                                                            to="/inquiryHistory"
                                                            className="mt-2 h6"
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            Inquiries
                                                        </Link>
                                                    </NavDropdown.Item>
                                                </NavDropdown>
                                                {/* expenses dropdown */}
                                                <NavDropdown
                                                    title="expenses "
                                                    id="collasible-nav-dropdown"
                                                    className={
                                                        (activeTab === 11
                                                            ? "bg-warning border border-warning rounded mr-5 h6"
                                                            : "h6") +
                                                        (userType == 3
                                                            ? " d-none"
                                                            : "")
                                                    }
                                                    onClick={() =>
                                                        setActiveTab(11)
                                                    }
                                                >
                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            setActiveTab(11)
                                                        }
                                                        className={
                                                            userType == 3
                                                                ? " d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <Link
                                                            to="/PartyManger"
                                                            className="mt-2 h6"
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            Parties
                                                        </Link>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            setActiveTab(11)
                                                        }
                                                        className={
                                                            userType == 3
                                                                ? " d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <Link
                                                            to="/expenses"
                                                            className="mt-2 h6"
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            Office Expenses
                                                        </Link>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            setActiveTab(11)
                                                        }
                                                        className={
                                                            userType == 3
                                                                ? " d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <Link
                                                            to="/workers"
                                                            className="mt-2 h6"
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            Workers
                                                        </Link>
                                                    </NavDropdown.Item>

                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            setActiveTab(11)
                                                        }
                                                        className={
                                                            userType == 3
                                                                ? " d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <Link
                                                            to="/BasicRates"
                                                            className="mt-2 h6"
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            Basic Rates
                                                        </Link>
                                                    </NavDropdown.Item>
                                                </NavDropdown>

                                                <Nav.Link>
                                                    <Button className="ml-5">
                                                        <ExitToAppIcon
                                                            onClick={(e) => {
                                                                localStorage.clear();
                                                                refresh();
                                                            }}
                                                        />
                                                    </Button>
                                                </Nav.Link>
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Container>
                                </Navbar>

                                <Routes>
                                    <Route
                                        path="/calc"
                                        element={
                                            <div className="">
                                                <div className="form my-5">
                                                    <div>
                                                        <button className="btn btn-sm btn-danger px-5 pt-2">
                                                            <h6>
                                                                {
                                                                    [
                                                                        "Mediator",
                                                                        "Retailer",
                                                                    ][
                                                                    currentType -
                                                                    1
                                                                    ]
                                                                }
                                                            </h6>
                                                        </button>
                                                    </div>
                                                    <div className="  inputes2 mt-2 mb-4 ">
                                                        <Autocomplete
                                                            id="free-solo-demo"
                                                            className="inputFieldp  mx-2"
                                                            sx={{
                                                                width: 300,
                                                            }}
                                                            options={
                                                                products != null
                                                                    ? products
                                                                    : []
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    // label="party name"
                                                                    label="Product"
                                                                    variant="outlined"
                                                                    size="small"
                                                                />
                                                            )}
                                                            onChange={(
                                                                event,
                                                                value
                                                            ) => {
                                                                if (
                                                                    value !=
                                                                    null
                                                                ) {
                                                                    console.log(
                                                                        value.price
                                                                    );
                                                                    setCurrentProduct(
                                                                        value.label
                                                                    );
                                                                    updateCurrentPrice(
                                                                        "product",
                                                                        value.price
                                                                    );
                                                                }
                                                            }}
                                                        />

                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={Unit}
                                                            className="inputField  mx-2"
                                                            size="small"
                                                            style={{
                                                                width: "150px",
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Unit"
                                                                />
                                                            )}
                                                            defaultValue="Sq.ft."
                                                            onChange={(
                                                                event,
                                                                value
                                                            ) => {
                                                                if (
                                                                    value !=
                                                                    null
                                                                ) {
                                                                    updateCurrentPrice(
                                                                        "unit",
                                                                        value.label
                                                                    );
                                                                }
                                                            }}
                                                        />

                                                        <TextField
                                                            id="unitPrice"
                                                            label="Unit Price / sq.ft."
                                                            variant="outlined"
                                                            size="small"
                                                            className="inputField  px-2"
                                                            style={{
                                                                width: "130px",
                                                            }}
                                                            value={
                                                                currentUnitPrice
                                                            }
                                                        />
                                                        <TextField
                                                            id="outlined-basic"
                                                            // value={34}
                                                            size="small"
                                                            label="Width"
                                                            className="inputField  px-2"
                                                            name="width"
                                                            type="number"
                                                            variant="outlined"
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                            value={currentWidth}
                                                            onChange={(e) => {
                                                                updateCurrentPrice(
                                                                    "width",
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                        <TextField
                                                            id="outlined-basic"
                                                            // value={34}
                                                            size="small"
                                                            label="Height"
                                                            className="inputField  px-2"
                                                            name="height"
                                                            type="number"
                                                            variant="outlined"
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                            value={
                                                                currentHeight
                                                            }
                                                            onChange={(e) => {
                                                                updateCurrentPrice(
                                                                    "height",
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                        <TextField
                                                            id="outlined-basic"
                                                            // value={34}
                                                            size="small"
                                                            label="Quantity"
                                                            className="inputField  px-2"
                                                            name="quantity"
                                                            type="number"
                                                            variant="outlined"
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                            value={
                                                                currentQuantity
                                                            }
                                                            onChange={(e) => {
                                                                updateCurrentPrice(
                                                                    "quantity",
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />

                                                        <TextField
                                                            id="sqft"
                                                            size="small"
                                                            label="Sq.ft."
                                                            className="inputField  px-2"
                                                            name="sqft"
                                                            variant="outlined"
                                                            style={{
                                                                width: "150px",
                                                            }}
                                                            value={currentSqft}
                                                            readOnly
                                                        />

                                                        <TextField
                                                            id="price"
                                                            size="small"
                                                            label="Price"
                                                            className="inputField  px-2"
                                                            name="Price"
                                                            type="number"
                                                            variant="outlined"
                                                            style={{
                                                                width: "150px",
                                                            }}
                                                            value={currentPrice}
                                                            onChange={(e) => {
                                                                setCurrentPrice(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        }
                                    />
                                    <Route
                                        path="/quote"
                                        element={
                                            <Quote
                                                currentCompany={currentCompany}
                                                currentType={currentType}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/gstBill"
                                        element={
                                            <GSTBill
                                                currentCompany={currentCompany}
                                                currentType={currentType}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/nonGstBill"
                                        element={
                                            <NONGSTBill
                                                currentCompany={currentCompany}
                                                currentType={currentType}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/"
                                        element={
                                            <Dashboard
                                                currentCompany={currentCompany}
                                                currentType={currentType}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/quoteHistory"
                                        element={<QuoteHistory />}
                                    />
                                    <Route
                                        path="/gstHistory"
                                        element={<GSTBillHistory />}
                                    />
                                    <Route
                                        path="/nonGstHistory"
                                        element={<NONGSTBillHistory />}
                                    />
                                    <Route
                                        path="/billHistory"
                                        element={<GSTNONGSTHistory />}
                                    />
                                    <Route
                                        path="/analytics"
                                        element={<Analytics />}
                                    />
                                    <Route
                                        path="/expenses"
                                        element={<ExpensesManager />}
                                    />

                                    <Route
                                        path="/products"
                                        element={
                                            <div className="">
                                                <div className="form my-5">
                                                    <div className="  inputes2 mt-2 mb-4 ">
                                                        <TextField
                                                            id="outlined-basic"
                                                            value={currentProduct}
                                                            size="small"
                                                            label="New Product Name"
                                                            className="inputFieldproduct mt-3 px-2"
                                                            name="newProduct"
                                                            type="text"
                                                            variant="outlined"
                                                            onChange={(e) => {
                                                                setCurrentProduct(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />

                                                        <TextField
                                                            id="outlined-basic"
                                                            // value={34}
                                                            size="small"
                                                            label="Base Price"
                                                            className="inputField  px-2"
                                                            name="basePrice"
                                                            type="number"
                                                            variant="outlined"
                                                            value={
                                                                currentUnitBasePrice
                                                            }
                                                            onChange={(e) => {
                                                                setCurrentUnitBasePrice(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                        <TextField
                                                            id="outlined-basic"
                                                            // value={34}
                                                            size="small"
                                                            label="Unit Price"
                                                            className="inputField  px-2"
                                                            name="unitPrice"
                                                            type="number"
                                                            variant="outlined"
                                                            value={
                                                                currentUnitPrice
                                                            }
                                                            onChange={(e) => {
                                                                setCurrentUnitPrice(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />

                                                        <Button
                                                            variant="contained"
                                                            className="AddButton mt-4"
                                                            onClick={
                                                                insertNewProduct
                                                            }
                                                        >
                                                            Add
                                                        </Button>
                                                    </div>
                                                </div>
                                                <hr />
                                                <table>
                                                    <tr>
                                                        <th>Product</th>

                                                        <th>Unit</th>
                                                        <th>Base Price</th>
                                                        <th>Rate / sq.ft.</th>

                                                        <th>Action</th>
                                                    </tr>

                                                    {products &&
                                                        products.map(
                                                            (item, id) => {
                                                                return (
                                                                    <tr
                                                                        key={id}
                                                                    >
                                                                        <td>
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            Sq.ft.
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.base_price
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.price
                                                                            }
                                                                        </td>

                                                                        <td>
                                                                            <span
                                                                                onClick={() =>
                                                                                    deleteProduct(
                                                                                        item.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <DeleteIcon color="error" />
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                </table>
                                            </div>
                                        }
                                    />
                                    <Route
                                        path="/workers"
                                        element={<WorkerManager />}
                                    />
                                    <Route
                                        path="/PartyManger"
                                        element={<PartyManger />}
                                    />
                                    <Route
                                        path="/ledgerManager/:partyId"
                                        element={<LedgerManager />}
                                    />
                                    <Route
                                        path="/presentyManager/:workerId"
                                        element={<PresentyManager />}
                                    />
                                    <Route
                                        path="/inquiry"
                                        element={
                                            <Inquiry
                                                currentCompany={currentCompany}
                                                currentType={currentType}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/inquiryHistory"
                                        element={
                                            <InquiryHistory
                                                userType={userType}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/inquiryProduct"
                                        element={
                                            <InquiryProduct
                                                currentCompany={currentCompany}
                                                currentType={currentType}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/BasicRates"
                                        element={
                                            <BasicRates
                                                currentCompany={currentCompany}
                                                currentType={currentType}
                                            />
                                        }
                                    />
                                </Routes>
                            </div>
                        )}
                    </HashRouter>
                    {!isLoggedIn && <Login />}
                </FilterContext.Provider>
            </AuthContext.Provider>
            <ToastContainer />
        </div>
    );
}
