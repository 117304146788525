import React, { Component } from "react";
import { toast } from "react-toastify";
import { Autocomplete, createSvgIcon, TextField } from "@mui/material";
// datatable
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import Button from "@mui/material/Button";

import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import CreateIcon from "@mui/icons-material/Create";
import logo from "../../images/logo.png";

//API handling components
import { API_URL } from "../../Global";

// datatable
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

const axios = require("axios");

// const Status = [{ label: "Sq.ft." }, { label: "Inch" }, { label: "MM" }];
export class InquiryHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userType: this.props.userType,
            showUpdateModel: false,
            currentInquiryId: null,
            currentInquiryReasonId: null,
            inquiryList: null,
            itemsList: null,
            printComponentRef: null,
            reason: "",
            currentInquiryStatus: null,
        };
    }

    fetchInquiryList = () => {
        let url = API_URL;
        const query = `SELECT * FROM inquiry as q where q.status!=0 ORDER BY q.id DESC;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("bill: ", res.data);
                this.setState({ inquiryList: res.data });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("Inquiry list fetch error: ", err);
            });
    };

    fetchInquiryItemList = () => {
        let url = API_URL;
        const query = `SELECT * FROM billList where billId = ${this.state.currentInquiryId} and billType=4;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("bill list data: ", res.data);
                this.setState({ itemsList: res.data });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("bill list fetch error: ", err);
            });
    };

    deleteRecord(id) {
        let url = API_URL;
        const query = `UPDATE inquiry SET status = 0  WHERE id=${id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("deleted status data: ", res.data);
                toast.success("Record deleted successfully");
                this.fetchInquiryList();
            })
            .catch((err) => {
                console.log("record delete error: ", err);
                toast.error("Failed to delete record");
            });
    }

    updateInquiryStatus(id, value) {
        let url = API_URL;
        const query = `UPDATE inquiry SET status = ${value}, updatedBy=${this.state.userType}  WHERE id=${id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("deleted status data: ", res.data);
                toast.success("Record deleted successfully");
                this.fetchInquiryList();
            })
            .catch((err) => {
                console.log("record delete error: ", err);
                toast.error("Failed to delete record");
            });
    }
    //   update reason

    handleUpdateSubmit(id) {
        // e.preventDefault();
        let url = API_URL;

        // const query = `UPDATE inquiry SET  reason="${this.state.reason}" WHERE id=2;`;
        const query = `UPDATE inquiry SET  reason="${this.state.reason}" WHERE id=${id} `;
        console.log(query);
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("inquiry reason updated successfully");
                this.fetchInquiryList();
            })
            .catch((err) => {
                console.log("error while updating inquiry reason", err);
            });
    }

    initializeDataTable() {
        $("#inquiryHistoryTable")
            .DataTable({
                destroy: true,
                dom:
                    "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                    "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                    "<'row'<'col-sm-12' tr>>" +
                    "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
                responsive: true,
                lengthChange: true,
                autoWidth: false,
                buttons: ["csv", "print"],
                order: [[0, "desc"]],
            })
            .buttons()
            .container()
            .appendTo("#sitesTable_wrapper .col-md-6:eq(0)");
    }

    componentDidMount() {
        this.fetchInquiryList();
    }

    renderInquiryList = () => {
        if (this.state.inquiryList == null) return null;

        // else
        return this.state.inquiryList.map((inquiry) => {
            return (
                <tr align="center">
                    <td>{inquiry.id}</td>
                    <td>{inquiry.partyName}</td>
                    <td>{inquiry.address}</td>
                    <td>{inquiry.mobileNo}</td>
                    <td>{inquiry.total}</td>
                    <td>{moment(inquiry.date).format("DD/MM/YYYY")}</td>
                    <td>{inquiry.inquiryPersonName}</td>
                    <td>{inquiry.reason}</td>
                    <td className="d-flex justify-content-center">
                        <select
                            name="status"
                            id="status"
                            value={inquiry.status}
                            onChange={(e) => {
                                this.updateInquiryStatus(
                                    inquiry.id,
                                    e.target.value
                                );
                            }}
                        >
                            <option value={1}>Pending</option>
                            <option value={2}>Completed</option>
                            <option value={3}>Cancel</option>
                        </select>
                        &nbsp;
                        <PreviewIcon
                            className="CPointer"
                            onClick={() => {
                                this.setState(
                                    {
                                        showUpdateModel: true,
                                        currentInquiryId: inquiry.id,
                                        inquiryNumber: inquiry.inquiryNumber,
                                        name: inquiry.partyName,
                                        address: inquiry.address,
                                        mobileNo: inquiry.mobileNo,
                                        total: inquiry.total,
                                        date: inquiry.date,
                                        status: inquiry.status,
                                        updatedBy: inquiry.updatedBy,
                                        inquiryPersonName:
                                            inquiry.inquiryPersonName,
                                    },
                                    this.fetchInquiryItemList
                                );
                            }}
                            color="success"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <CreateIcon
                            className="CPointer"
                            onClick={(e) => {
                                this.setState({
                                    currentInquiryReasonId: inquiry.id,
                                    showUpdateModal: true,
                                });
                            }}
                            color="info"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <DeleteIcon
                            className="CPointer"
                            onClick={(e) => {
                                if (window.confirm("Delete the item?")) {
                                    this.deleteRecord(inquiry.id);
                                }
                            }}
                            color="error"
                        />
                    </td>
                    {/* {  console.log("inqss"+ this.state.currentInquiryId)} */}
                    {/* edit  reason model  */}
                    <Modal
                        show={this.state.showUpdateModal}
                        onHide={(e) =>
                            this.setState({ showUpdateModal: false })
                        }
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Enter Reason
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form noValidate autoComplete="off">
                                <div className="mt-3">
                                    <Row>
                                        <Col size="12">
                                            <TextField
                                                id="reason"
                                                label="Reason"
                                                variant="outlined"
                                                className="m-2"
                                                onChange={(e) => {
                                                    this.setState({
                                                        reason: e.target.value,
                                                    });
                                                    // console.log(e.target.value)
                                                    // console.log("reason", this.state.reason);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <hr />
                                <div className="mt-2 mr-1">
                                    <Button
                                        variant="contained"
                                        style={{ float: "right" }}
                                        onClick={(e) => {
                                            this.setState({
                                                showUpdateModal: false,
                                            });
                                            this.handleUpdateSubmit(
                                                this.state
                                                    .currentInquiryReasonId
                                            );
                                        }}
                                    >
                                        Update
                                    </Button>
                                    {console.log(
                                        "inqss" +
                                            this.state.currentInquiryReasonId
                                    )}
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                </tr>
            );
        });
    };

    render() {
        return (
            <div className="">
                <Row>
                    <Col
                        md="12"
                        className="m-0 p-1 measure1"
                        style={{ minHeight: "85vh" }}
                    >
                        <div>
                            <table
                                id="inquiryHistoryTable"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr align="center">
                                        <th align="center">Inquiry Ids</th>
                                        <th align="center">Name</th>
                                        <th align="center">Address</th>
                                        <th align="center">Mobile No.</th>
                                        <th align="center">Total</th>
                                        <th align="center">Date</th>
                                        <th align="center">Inquiry Person</th>
                                        <th align="center">Reason</th>
                                        <th align="center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>{this.renderInquiryList()}</tbody>
                            </table>
                            <Modal
                                onHide={(e) =>
                                    this.setState({ showUpdateModel: false })
                                }
                                size="lg"
                                show={this.state.showUpdateModel}
                                // onHide={() => setLgShow(false)}
                                aria-labelledby="example-modal-sizes-title-lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Bill Details</Modal.Title>
                                </Modal.Header>
                                <Row>
                                    {/* Overview */}

                                    <div
                                        className="ReceptContainerss"
                                        //   ref={(el) => setPrintComponentRef(el)}
                                        ref={(el) =>
                                            (this.printComponentRef = el)
                                        }
                                    >
                                        <div className="text-center">
                                            <div className="header  ReceptBg ">
                                                <div className="row logoContainer">
                                                    <div className="col-6 text-start">
                                                        <img
                                                            src={logo}
                                                            alt="logo"
                                                            className="img-fluid"
                                                        />
                                                    </div>

                                                    <div className="col-6">
                                                        <p className="py-3 pl-4 text-start">
                                                            Shop No.2, vithoba
                                                            Complex,
                                                            Sangli-Miraj Road{" "}
                                                            <br />{" "}
                                                            Nr.Parshwanthnagar
                                                            Bus Stop, Miraj -
                                                            416 410 (Maha){" "}
                                                            <br /> Mobile:
                                                            7293242424, e-mail:
                                                            imprint24.india@gmail.com
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className="bg-secondary text-white px-2">
                                                INQUIRY
                                            </h5>

                                            <div className="p-2 text-start">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p className="text-start">
                                                            Party Name: &nbsp;{" "}
                                                            {this.state.name}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            {" "}
                                                            Phone No: &nbsp;
                                                            {
                                                                this.state
                                                                    .mobileNo
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>
                                                            Address: &nbsp;{" "}
                                                            {this.state.address}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            Date :
                                                            <b>
                                                                {" "}
                                                                {moment(
                                                                    this.state
                                                                        .date
                                                                ).format(
                                                                    "D/M/YYYY"
                                                                )}
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>
                                                            Inquiry Number :
                                                            &nbsp;{" "}
                                                            {
                                                                this.state
                                                                    .inquiryNumber
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            Updated by : &nbsp;{" "}
                                                            {
                                                                [
                                                                    "admin",
                                                                    "signmart",
                                                                ][
                                                                    this.state
                                                                        .updatedBy -
                                                                        1
                                                                ]
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            inquiry Person Name
                                                            : &nbsp;{" "}
                                                            {
                                                                this.state
                                                                    .inquiryPersonName
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <h6 className="bg-secondary text-secondary px-2">
                                                .
                                            </h6>

                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>Sq. ft.</th>
                                                        <th>Unit</th>
                                                        <th>Rate / sq.ft.</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.itemsList &&
                                                        this.state.itemsList.map(
                                                            (item, id) => {
                                                                return (
                                                                    <tr
                                                                        key={id}
                                                                    >
                                                                        <td>
                                                                            {id +
                                                                                1}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.particular
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.quantity
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.sqft
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            Sq.
                                                                            ft.
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.rate
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {Number(
                                                                                item.amount
                                                                            ).toFixed(
                                                                                2
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <b>Grand Total</b>
                                                        </td>
                                                        <td>
                                                            <b>
                                                                {this.state
                                                                    .total &&
                                                                    this.state.total.toFixed(
                                                                        2
                                                                    )}
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <div className="Qrcode">
                                                        <img
                                                            src="/Assets/QrCode1.jpg"
                                                            className="qrimg"
                                                            alt="QrCode"
                                                        />
                                                    </div>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="">
                                        <ReactToPrint
                                            content={() =>
                                                this.printComponentRef
                                            }
                                        >
                                            <PrintContextConsumer>
                                                {({ handlePrint }) => (
                                                    <Button
                                                        onClick={handlePrint}
                                                        className="mb-5 mt-3 mr-3"
                                                        color="primary"
                                                        variant="contained"
                                                        style={{
                                                            float: "right",
                                                            marginRight: "40px",
                                                        }}
                                                    >
                                                        PRINT
                                                    </Button>
                                                )}
                                            </PrintContextConsumer>
                                        </ReactToPrint>
                                    </div>
                                    {/* invoice */}
                                </Row>
                            </Modal>
                            {/* edit reason model */}

                            {/* {this.renderUpdateModal()}
                            {this.renderDisplayBillModal()} */}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default InquiryHistory;
